* :not(ul, ol) {
    margin: 0;
    padding: 0;
}

html {
    font-style: normal;
    font-weight: normal;
}

body,
html {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    cursor: default;
}

@font-face {
    font-family: "Angelina-rnlA";
    src: url("/assets/fonts/Angelina-rnlA.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}
